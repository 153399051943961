import Link from "./bases/link"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useIntl } from "../hooks"
import { Box, Button, Heading, Image } from "grommet"
import { Spinner, toast } from "."
import { reportError, redirect, fetchApi, navigate } from "../utils"
import { mutate } from "swr"

const App = props => {
  const { name: clientName, provider, clientId } = props
  const { formatMessage } = useIntl()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [reconnectLoading, setRegenereateLoading] = useState(false)

  const [isDeleted, setIsDeleted] = useState(false)
  const handleDelete = async () => {
    setDeleteLoading(true)
    try {
      await fetchApi(`/clients/${clientId}`, {
        method: "DELETE",
      })
      setDeleteLoading(false)
      setIsDeleted(true)
      toast.success(
        formatMessage({
          id: "remove_client_success",
          defaultMessage: "The client has been removed",
        })
      )
    } catch (error) {
      setDeleteLoading(false)
      toast(error.message)
      reportError(error)
    }
  }

  const handleRotateSecret = async () => {
    setRegenereateLoading(true)
    try {
      const rotateData = await fetchApi(`/clients/${clientId}/rotate-secret`, {
        method: "POST",
      })
      setRegenereateLoading(false)
      mutate(`/clients/${clientId}`, rotateData, false)
      navigate(`/clients/${clientId}`)
      // TODO mutate, navigate
    } catch (error) {
      setRegenereateLoading(false)
      toast(error.message)
      reportError(error)
    }
  }

  if (isDeleted) {
    return null
  }
  return (
    <Box margin={{ bottom: "medium" }}>
      <Box
        pad="medium"
        round
        direction="row"
        gap="medium"
        align="center"
        border={{
          size: "small",
          color: "app-border",
        }}
      >
        <Box>
          <Link to={`/clients/${clientId}`}>
            <Heading level="2">{clientName}</Heading>
          </Link>
          <Box direction="row" gap="small">
            <Button
              disabled={reconnectLoading}
              icon={reconnectLoading ? <Spinner /> : null}
              secondary
              onClick={handleRotateSecret}
              label={formatMessage({
                id: "regenerate_client_secret",
                defaultMessage: "Regenerate client secret",
              })}
            ></Button>
            <Button
              disabled={deleteLoading}
              icon={deleteLoading ? <Spinner /> : null}
              secondary
              onClick={handleDelete}
              label={formatMessage({
                id: "delete",
                defaultMessage: "Delete",
              })}
            ></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

App.propTypes = {
  name: PropTypes.string,
}

App.defaultProps = {
  name: ``,
}

export default App
