import React from "react"
import { Twitter, Instagram } from "grommet-icons"
import { Box, Image } from "grommet"
const directIcon = {
  twitter: {
    component: Twitter,
    props: {
      color: "#38A1F3",
    },
  },
  instagram: {
    component: Instagram,
    props: {
      color: "#E1306C",
    },
  },
}

const AppIcon = ({ size, provider, picture }) => {
  if (directIcon[provider]) {
    const Icon = directIcon[provider].component
    const iconProps = directIcon[provider].props
    return (
      <Box>
        <Icon
          size={size === "large" ? "xlarge" : "large"}
          {...iconProps}
        ></Icon>
      </Box>
    )
  }
  return (
    <Box
      height={size === "large" ? "small" : "xxsmall"}
      width={size === "large" ? "small" : "xxsmall"}
    >
      <Image
        src={picture}
        fill="vertical"
        fallback={`${provider}-logo`}
        a11yTitle={`${provider}-logo`}
      ></Image>
    </Box>
  )
}
export default AppIcon
