import Link from "./bases/link"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useIntl } from "../hooks"
import { Box, Button, Heading, Text, ThemeContext } from "grommet"
import { Spinner, toast, AppIcon, Status } from "../components"
import { reportError, redirect, fetchApi, encodeAppState } from "../utils"
import styled from "styled-components"
import { formatRelative } from "date-fns"

const App = props => {
  const {
    app,
    name: connectionName,
    provider,
    connectionId,
    updatedAt,
    connectionStatus,
    isShowIcon = true,
    index: connectionIndex,
    onRemove,
  } = props
  const { picture, name } = app
  const { formatMessage } = useIntl()
  const [testLoading, setTestLoading] = useState(false)
  const [disconnectLoading, setDisconnectLoading] = useState(false)
  const [reconnectLoading, setReconnectLoading] = useState(false)
  const handleDisconnect = async () => {
    setDisconnectLoading(true)
    try {
      await fetchApi(`/connections/${connectionId}`, {
        method: "DELETE",
      })
      if (onRemove) {
        onRemove({
          index: connectionIndex,
        })
      }
    } catch (error) {
      setDisconnectLoading(false)
      toast(error.message)
      reportError(error)
    }
  }

  const handleReconnectConnection = async () => {
    setReconnectLoading(true)
    try {
      const authenticatedUrlData = await fetchApi(
        `/authenticated-url/${provider}?app_state=${encodeAppState({
          action: "reconnect",
          payload: {
            connectionId: connectionId,
          },
        })}`
      )
      setReconnectLoading(false)
      if (
        authenticatedUrlData &&
        authenticatedUrlData.data &&
        authenticatedUrlData.data.location
      ) {
        redirect(authenticatedUrlData.data.location)
      }
    } catch (error) {
      setReconnectLoading(false)
      toast(error.message)
      reportError(error)
    }
  }
  const handleTest = async () => {
    setTestLoading(true)
    try {
      await fetchApi(`/connections/${connectionId}/status`)
      setTestLoading(false)
      toast.success(
        formatMessage({
          id: "test_success",
          defaultMessage: "The connection is active",
        })
      )
    } catch (error) {
      setTestLoading(false)
      toast(error.message)
      reportError(error)
    }
  }

  const ConnectionBox = styled(Box)`
    transition: box-shadow 0.25s ease 0s, transform 0.25s ease 0s;
    :hover {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
      transform: translateY(-1px) rotate(0.01deg);
    }
  `
  return (
    <Box overflow="visible" fill="horizontal" margin={{ bottom: "medium" }}>
      <ConnectionBox
        overflow="visible"
        direction="row"
        gap="medium"
        align="center"
        fill="horizontal"
        round="xsmall"
        pad={{ bottom: "large", left: "medium", right: "medium" }}
        elevation="medium"
      >
        {isShowIcon && (
          <Box
            background="backgroud"
            border={{
              size: "medium",
              color: "app-icon-border",
            }}
            round="medium"
            pad="medium"
          >
            <AppIcon provider={provider} picture={picture} />
          </Box>
        )}

        <Box fill="horizontal">
          <Box
            align="baseline"
            gap="small"
            pad={{ top: "small", bottom: "small" }}
            direction="row"
          >
            <Status
              status={connectionStatus === "active" ? "online" : "offline"}
            ></Status>
            <ThemeContext.Extend
              value={{
                anchor: {
                  hover: {
                    textDecoration: "none",
                  },
                },
              }}
            >
              <Link to={`/connections/${connectionId}`}>
                <Heading level="3" color="text">
                  {connectionName}
                </Heading>
              </Link>
            </ThemeContext.Extend>
          </Box>

          <Box direction="row" justify="between" fill="horizontal">
            <Box>
              <Text color="dark-4">
                {formatRelative(new Date(updatedAt), new Date())}
              </Text>
            </Box>
            <Box direction="row" gap="small" justify="end">
              <Button
                disabled={disconnectLoading}
                icon={disconnectLoading ? <Spinner /> : null}
                secondary
                size="small"
                onClick={handleDisconnect}
                label={formatMessage({
                  id: "disconnect",
                  defaultMessage: "Disconnect",
                })}
              ></Button>
              <Button
                disabled={reconnectLoading}
                icon={reconnectLoading ? <Spinner /> : null}
                primary
                size="small"
                onClick={handleReconnectConnection}
                label={formatMessage({
                  id: "reconnect",
                  defaultMessage: "Reconnect",
                })}
              ></Button>
            </Box>
          </Box>
        </Box>
      </ConnectionBox>
    </Box>
  )
}

App.propTypes = {
  name: PropTypes.string,
}

App.defaultProps = {
  name: ``,
}

export default App
