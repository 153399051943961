import React from "react"
import { Box, Text } from "grommet"
const Component = ({ size = "small", status = "online" }) => {
  const sizePx = size === "small" ? "12px" : "24px"
  const statusMap = {
    online: "status-ok",
    offline: "status-error",
  }
  const color = statusMap[status]
  return (
    <Box round="full" background={color} height={sizePx} width={sizePx}></Box>
  )
}

export default Component
