import React from "react"
import { Box, Text, Heading, Button } from "grommet"
const Component = ({ title = "Tips", text = "", button } = {}) => {
  return (
    <Box align="center" pad="medium">
      <Heading color="dark-2" level="2">
        {title}
      </Heading>
      <Text color="dark-3">{text}</Text>
      {button && (
        <Box pad={{ top: "large" }}>
          <Button size="large" {...button}></Button>
        </Box>
      )}
    </Box>
  )
}

export default Component
