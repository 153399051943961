import React from "react"
import { Box, Heading } from "grommet"
import { FormattedMessage } from "../../utils"
const Component = ({ children }) => {
  return (
    <Box pad={{ left: "large", bottom: "large" }}>
      <Heading size="xlarge" level="1">
        <FormattedMessage
          id="error_hero"
          defaultMessage="Oops!"
        ></FormattedMessage>
      </Heading>
      <Box pad={{ left: "xsmall" }}>
        <Heading level="2">{children}</Heading>
      </Box>
    </Box>
  )
}

export default Component
