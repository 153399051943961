import Link from "./bases/link"
import PropTypes from "prop-types"
import React from "react"
import { Box, Heading } from "grommet"
import styled from "styled-components"
import AppIcon from "./app-icon"
const AppBox = styled(Box)`
  transition: box-shadow 0.25s ease 0s, transform 0.25s ease 0s;
  :hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
    transform: translateY(-1px) rotate(0.01deg);
  }
`
const AppLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`
const App = ({ name, picture, provider }) => {
  return (
    <Box pad={{ top: "medium", bottom: "medium" }} fill={true}>
      <AppLink color="text" to={`/apps/${provider}`}>
        <AppBox
          height="xsmall"
          round="xsmall"
          elevation="medium"
          direction="row"
          gap="medium"
          align="center"
        >
          <Box background="backgroud" round="medium" pad="medium">
            <AppIcon picture={picture} provider={provider}></AppIcon>
          </Box>
          <Heading level="3">{name}</Heading>
        </AppBox>
      </AppLink>
    </Box>
  )
}

App.propTypes = {
  name: PropTypes.string,
}

App.defaultProps = {
  name: ``,
}

export default App
