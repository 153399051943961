import React from "react"
import { Box, Heading } from "grommet"
import Spinner from "./spinner"

export default function ({ text }) {
  return (
    <Box
      direction="row"
      align="center"
      justify="center"
      gap="small"
      pad="medium"
      round="medium"
    >
      <Spinner />
      <Heading level="3">{text}</Heading>
    </Box>
  )
}
