import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useAuth0, useLocation } from "../../hooks"
import { withSearch, debug } from "../../utils"
import { LoadingWithText } from ".."
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const pageLocation = useLocation()

  const auth0 = useAuth0()
  useEffect(() => {
    if (!auth0) {
      return
    }
    const { loading, isAuthenticated, loginWithRedirect } = auth0

    if (loading || isAuthenticated) {
      return undefined
    }
    const asyncLogin = async () => {
      const appState = { targetUrl: withSearch(pageLocation.location.pathname) }
      debug("appState: %o", appState)
      await loginWithRedirect({
        appState,
      })
    }
    asyncLogin()
  }, [
    pageLocation.originPath,
    pageLocation.originalPath,
    auth0,
    pageLocation.path,
    pageLocation.location.pathname,
  ])

  if (auth0 && auth0.loading === false && auth0.isAuthenticated === true) {
    return <Component {...rest}></Component>
  } else {
    return <LoadingWithText text="Loading..."></LoadingWithText>
  }
}

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func]).isRequired,
}

export default ProtectedRoute
